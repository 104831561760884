import React from 'react';
import {
  TeliaRow,
  TeliaHeading,
  TeliaP,
  TeliaButton,
} from '@teliads/components/react';
import { Link } from 'gatsby';

import '../styles/onboarding.scss';

type OnboardingType = {
  title: string;
  message: string;
  buttonText: string;
  nextPage: string;
  subMessage?: string;
  url?: string;
};

export const Onboarding = ({
  title,
  message,
  buttonText,
  url,
  subMessage,
  nextPage,
}: OnboardingType) => {
  return (
    <TeliaRow className="onboarding column gap center padding--vertical">
      {url && <img src={url} />}
      <TeliaHeading tag="h2" variant="display-100">
        {title}
      </TeliaHeading>
      <TeliaP>{message}</TeliaP>
      {subMessage && <TeliaP>{subMessage}</TeliaP>}
      <Link to={nextPage}>
        <TeliaButton variant="primary" text={buttonText} />
      </Link>
    </TeliaRow>
  );
};
